import select from 'dom-select';
import _ from 'lodash';
import throttle from 'lodash.throttle';

export default (el) => {
  const ui = {
    el,
    map: select('.interactive-map__map', el),
    viewport: select('.interactive-map__viewport', el),
    control: select('.interactive-map__control', el)
  };

  const calcPosition = () => {
    const position = control.value;
    const mapWidth = ui.map.clientWidth;
    const viewportWidth = ui.viewport.clientWidth;
    let offset = 0;

    if (position == '1') {
      offset = '0';
    }
    if (position == '2') {
      offset = (viewportWidth - mapWidth) * (.5);
    }
    if (position == '3') {
      offset = (viewportWidth - mapWidth) * (1);
    }

    ui.map.setAttribute('style', 'transform: translateX(' + offset + 'px)');
  }

  const addEvents = () => {
    ui.control.addEventListener('change', () => {
      calcPosition();
    });
  };

  window.addEventListener('resize', throttle(() => {
    calcPosition();
  },
  250));

  const init = () => {
    addEvents();
  };

  init();
};
