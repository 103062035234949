import Flickity from 'flickity';
import select from 'dom-select';
import throttle from 'lodash.throttle';
import 'flickity-imagesloaded';
import 'flickity-fade';

export default (el) => {
  const ui = {
    el,
    container: select('.carousel__container', el),
    cells: select.all('.carousel__cell', el),
    index: select('.carousel__index', el)
  };

  if (ui.cells.length > 1) {
    const flkty = new Flickity(ui.container, {
      adaptiveHeight: true,
      autoPlay: 0,
      fade: true,
      imagesLoaded: true,
      pageDots: false,
      wrapAround: true,
      prevNextButtons: true,
      lazyLoad: true
    });

    flkty.on('change', (index) => {
      ui.index.innerHTML = (index + 1);
    });

    // resize carousel cells on (throttled) window.resize
    window.addEventListener('resize', throttle(() => {
      flkty.resize();
    },
    250,
    {
      trailing: true,
      leading: true
    }));
  }
};
