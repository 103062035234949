import A11yDialog from 'a11y-dialog';
import Flickity from 'flickity';
import select from 'dom-select';

export const Modal = (el) => {
  const ui = {
    el,
    closeBtn: select('.modal__close', el),
    carousel: select('.carousel__container', el)
  };

  const { id } = ui.el;

  ui.toggle = document.querySelector(`#opens--${id}`);

  const dialog = new A11yDialog(ui.el);

  const addEvents = () => {
    ui.toggle.addEventListener('click', () => {
      dialog.show();
    });

    dialog.on('show', () => {
      ui.closeBtn.blur();

      if (ui.carousel) {
        const flkty = Flickity.data(ui.carousel);
        ui.carousel.classList.add('is-open');
        flkty.resize();
      }
    });

    dialog.on('hide', () => {
      if (ui.carousel) {
        ui.carousel.classList.remove('is-open');
      }
    });
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default Modal;
