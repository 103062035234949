import select from 'dom-select';
import windowIsLargerThan from '@lib/measureViewport';
import { closeMenu } from '@components/global-header/partials/nav-item/nav-item.init';

const closeMainMenu = new Event('closeMainMenu'); // eslint-disable-line

export const Navigation = (el) => {
  const ui = {
    el,
    items: select.all('.nav-item', el),
    toggle: select('.navigation__toggle', el),
    back: select('.navigation__back', el),
    utility: select('.nav-utility', document),
    utilityItems: null,
    globalHeader: select('.global-header', document),
    body: select('body', document)
  };

  const breakpoint = 896;

  const toggleScroll = (bool) => state.isScrolled = bool;

  const state = {
    desktop: windowIsLargerThan(breakpoint),
    throttle: false,
    navOpen: false,
    subNavOpen: false
  };

  const throttle = (func, limit, ...args) => {
    const context = this;
    if (state.throttle === false) {
      func.apply(context, args);
      state.throttle = true;
      setTimeout(() => { state.throttle = false; }, limit);
    }
  };

  const openNavMenu = (e) => {
    const { type } = e;

    ui.el.classList.add('nav-open');
    ui.globalHeader.classList.add('nav-open');
    ui.toggle.setAttribute('aria-expanded', true);
    ui.body.classList.add('lock-body');

    ui.items.forEach((item) => {
      item.removeAttribute('hidden');

      item.querySelector('.nav-item__inner').setAttribute('tabIndex', 0);
    });

    if (state.desktop === false && type === 'keydown') {
      ui.items[0].querySelector('.nav-item__inner').focus();
    }

    state.navOpen = true;
    state.subNavOpen = false;
  };

  const closeNavMenu = () => {
    ui.el.classList.remove('nav-open');
    ui.el.classList.remove('sub-open');
    ui.globalHeader.classList.remove('nav-open');
    ui.toggle.setAttribute('aria-expanded', false);
    ui.body.classList.remove('lock-body');

    setTimeout(() => {
      if (!state.desktop) {
        ui.items.forEach((item) => {
          item.setAttribute('hidden', true);
          item.classList.remove('is-open');

          item.querySelector('.nav-item__inner').setAttribute('tabIndex', -1);

          item.dispatchEvent(closeMenu);
        });
      }
    }, 250);

    state.navOpen = false;
    state.subNavOpen = false;
  };

  const handleResize = () => {
    if (windowIsLargerThan(breakpoint) === true) {
      ui.el.dispatchEvent(closeMainMenu);
      ui.items.forEach((item) => {
        item.removeAttribute('hidden');
        item.classList.remove('is-hidden');
        const inner = select('.nav-item__inner', item);
        inner.setAttribute('tabIndex', 0);
      });

      ui.toggle.setAttribute('hidden', true);
      ui.toggle.setAttribute('tabIndex', -1);

      if (ui.utilityItems !== null) {
        ui.utilityItems.forEach((item) => {
          item.firstChild.setAttribute('tabIndex', 0);
        });
      }
    } else if (windowIsLargerThan(breakpoint) === false) {
      closeNavMenu();
      ui.toggle.removeAttribute('hidden');
      ui.toggle.setAttribute('tabIndex', 0);

      ui.items.forEach((item) => {
        item.classList.remove('is-hidden');
        item.removeAttribute('hidden');
        const inner = select('.nav-item__inner', item);
        inner.setAttribute('tabIndex', -1);
      });

      if (ui.utilityItems !== null) {
        ui.utilityItems.forEach((item) => {
          item.firstChild.setAttribute('tabIndex', 1);
        });
      }
    }

    state.desktop = windowIsLargerThan(breakpoint);
  };

  const handleToggle = (e) => {
    e.preventDefault();

    if (state.navOpen === false) {
      openNavMenu(e);
    } else {
      closeNavMenu();
    }
  };

  const addEventListeners = () => {
    window.addEventListener('resize', () => {
      if (state.desktop !== windowIsLargerThan(breakpoint)) {
        throttle(handleResize, 200);
      }
    });

    window.addEventListener('scroll', (e) => {
      const { pageYOffset } = window;

      if (pageYOffset > 24) {
        toggleScroll(true);
      } else {
        toggleScroll(false);
      }

      // console.log(toggleScroll);
      updateStyles();
    });

    ui.el.addEventListener('closeMainMenu', () => {
      closeNavMenu();
    });

    ui.toggle.addEventListener('click', (e) => handleToggle(e));
    ui.toggle.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        handleToggle(e);
      } else if (e.keyCode === 9) {
        e.preventDefault();

        if (state.subNavOpen) {
          const openItem = select('.nav-item.is-open');

          const openLink = select('.link', openItem);
          openLink.focus();
        } else if (state.navOpen) {
          const button = select('.nav-item__toggle', ui.items[0]);
          button.focus();
        }
      }
    });

    ui.items.forEach((item, index) => {
      const button = select('.nav-item__toggle', item);
      const inner = select('.nav-item__inner', item);

      if (button !== null) {
        button.addEventListener('click', () => {
          state.subNavOpen = true;
          ui.el.classList.add('sub-open');
        });

        button.addEventListener('keydown', (e) => {
          const { keyCode } = e;

          if (keyCode !== 13) return;

          state.subNavOpen = true;
          ui.el.classList.add('sub-open');
        });
      }

      inner.addEventListener('keydown', (e) => {
        const { keyCode } = e;

        if (windowIsLargerThan(breakpoint) === false) {
          if (keyCode === 38) { // UP ARROW
            if (index !== 0) {
              ui.items[index - 1].querySelector('.nav-item__inner').focus();
            } else {
              ui.items[ui.items.length - 1].querySelector('.nav-item__inner').focus();
            }
          } else if (keyCode === 40) { // DOWN ARROW
            if (index !== ui.items.length - 1) {
              ui.items[index + 1].querySelector('.nav-item__inner').focus();
            } else {
              ui.items[0].querySelector('.nav-item__inner').focus();
            }
          } else if (keyCode === 9) { // TAB
            e.preventDefault();
            ui.toggle.focus();
          }
        }
      });
    });
  };

  const updateStyles = () => {
    if (state.isScrolled) {
      ui.globalHeader.classList.add('is-scrolled');

    } else {
      ui.globalHeader.classList.remove('is-scrolled');
    }
  };

  const init = () => {
    if (windowIsLargerThan(breakpoint) === true) {
      ui.toggle.setAttribute('hidden', true);
      ui.toggle.setAttribute('tabIndex', -1);

      ui.items.forEach((item) => {
        item.classList.remove('is-hidden');
        item.removeAttribute('hidden');

        item.querySelector('.nav-item__inner').setAttribute('tabIndex', 0);
      });
    }

    addEventListeners();
  };

  init();
};

export default Navigation;
