import select from 'dom-select';

export const Gallery = (el) => {
  const ui = {
    el,
    cells: el.querySelectorAll('.gallery__cell'),
    img: select('.gallery__img', el),
    imgTitle: select('.gallery__img-title'),
    imgContent: select('.gallery__img-content'),
  };

  const addEvents = () => {
    ui.cells.forEach((cell) => {



      cell.addEventListener('click', () => {
        const imgSrc = cell.getAttribute('data-imgsrc');
        const imgAlt = cell.getAttribute('data-imgalt');
        const imgTitle = cell.getAttribute('data-imgtitle');
        const imgContent = cell.getAttribute('data-imgcontent');
        ui.img.setAttribute('src', imgSrc);
        ui.img.setAttribute('alt', imgAlt);
        ui.imgTitle.textContent = imgTitle;
        ui.imgContent.innerHTML = imgContent;
      })
    })
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default Gallery;
  